import { ShowAdviceCommunication } from "./AdviceTopics";
import { eventQBus } from "../../types/EventQBus";

function showMessage(type: string, message: string): string {
  return (
    '<div class="pl_banner pl_banner--' +
    type +
    '"><span>' +
    message +
    '</span><svg class="pl_icon50 js_pl_banner__close" role="img"><title>Hinweis schließen</title><use xlink:href="/assets-static/icons/pl_icon_close50.svg#pl_icon_close50"></use></svg>' +
    "</div>"
  );
}

function getGlobalAdviceSpan(): Element | null {
  return document.getElementById("reptile-advice");
}

/**
 *
 */
function onShowAdvice(event: ShowAdviceCommunication) {
  const elem = getGlobalAdviceSpan();
  if (elem) {
    elem.innerHTML = showMessage(event.type || "error", event.message || "Das hat leider nicht funktioniert.");
  }
}

function init() {
  eventQBus.on("ftfind.advice.show", onShowAdvice);
}

export default function registerAdviceCommunication(): void {
  eventQBus.once("heureka.filters.loaded", () => init());
}
