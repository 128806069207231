export function getMergeParameterQuery(): string {
  if (window.o_tracking && window.o_tracking.bct && typeof window.o_tracking.bct.getMergeParameters === "function") {
    return window.o_tracking.bct.getMergeParameters();
  }
  return "";
}

export function replaceTrackingContext(id: string, url: string): void {
  if (window.o_tracking && window.o_tracking.bct && typeof window.o_tracking.bct.replaceContext === "function") {
    window.o_tracking.bct.replaceContext(id, url, {});
  }
}
