import { DresonRuleResolvedEvent } from "../../types/FilterTypes";
import { eventQBus } from "../../types/EventQBus";
import { getMergeParameterQuery } from "../../tracking/TrackingAPI";

export default class LoadCategorySlider {
  private static readonly SES: string = "Suchergebnisseite";

  static onLoadCategorySlider(data: DresonRuleResolvedEvent): void {
    const findCategorySliderStartNode: HTMLElement | null = document.querySelector("#find_category_slider__start");
    const findCategorySliderEndNode: HTMLElement | null = document.querySelector("#find_category_slider__end");
    if (!findCategorySliderStartNode || !findCategorySliderEndNode) {
      return;
    }

    /*                                                                           */
    const { rule } = findCategorySliderStartNode.dataset;
    if (rule === data.rule || LoadCategorySlider.currentPageType() === LoadCategorySlider.SES) {
      return;
    }

    fetch(LoadCategorySlider.uri(data.rule))
      .then(LoadCategorySlider.extractResponseText)
      .then((html: string) =>
        LoadCategorySlider.replaceNodeContents(findCategorySliderStartNode, findCategorySliderEndNode, data.rule, html)
      )
      .then(LoadCategorySlider.emitCategorySliderLoaded)
      .catch((e: Error) => {
        console.warn(`Filter reload AJAX GET against ${LoadCategorySlider.uri(data.rule)} failed: ${e.message}`);
      });
  }

  private static extractResponseText(xhr: Response) {
    if (xhr.status !== 200 && xhr.status !== 204) {
      throw Error(`Failed to retrieve category slider: ${xhr.status} ${xhr.statusText}`);
    }
    return xhr.text();
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  private static replaceNodeContents(startNode: HTMLElement, endNode: HTMLElement, rule: string, html: string) {
    /*                                 */
    const range = document.createRange();
    range.setStartAfter(startNode);
    range.setEndBefore(endNode);

    /*                                                               */
    const fragment = range.createContextualFragment(html);

    /*           */
    range.deleteContents();
    range.insertNode(fragment);

    /*                     */
    startNode.dataset.rule = rule;

    /*                                                                     */
    /*         */
    window.preload_polyfill_invoke(fragment);
  }

  private static emitCategorySliderLoaded(): void {
    eventQBus.emit("ftfind.categorySlider.loaded");
  }

  private static uri(dataRule: string): string {
    const ruleString = `${encodeURIComponent(dataRule)}`;
    return `/nav-promirnaty/category-slider/${ruleString}?${getMergeParameterQuery()}`;
  }

  private static currentPageType() {
    const elem = document.getElementById("avContent");
    return elem && elem.dataset.pt;
  }
}
