const SFID_WRAPPER = "reptile-sfid-wrapper";
const TS_BCT = ".ts-bct";

export class SfidWrapper {
  static ssid() {
    const wrapper = document.getElementById(SFID_WRAPPER);

    if (wrapper) {
      const tsBct = wrapper.querySelector(TS_BCT) as HTMLElement;
      if (tsBct) {
        return tsBct.dataset.ts_sfid;
      }
    }

    return;
  }
}
