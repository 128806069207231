import { DresonRuleResolvedEvent } from "../../types/FilterTypes";
import { eventQBus } from "../../types/EventQBus";
import { getMergeParameterQuery } from "../../tracking/TrackingAPI";

export default class OttoUp {
  static onLoadOttoUp(data: DresonRuleResolvedEvent): void {
    const ottoUpNode: HTMLElement | null =
      document.getElementById("reptile-ottoup") || document.getElementById("san_ottoUp");
    if (!ottoUpNode) {
      return;
    }

    const { rule } = ottoUpNode.dataset;
    if (rule === data.rule) {
      return;
    }

    fetch(OttoUp.uri(data.rule))
      .then(OttoUp.extractResponseText)
      .then((html: string) => OttoUp.replaceOttoUp(ottoUpNode, data.rule, html))
      .then(OttoUp.emitOttoUpLoaded)
      .catch((e: Error) => {
        console.warn(`Filter reload AJAX GET against ${OttoUp.uri(data.rule)} failed: ${e.message}`);
      });
  }

  private static extractResponseText(xhr: Response) {
    if (xhr.status !== 200 && xhr.status !== 204) {
      throw Error(`Failed to retrieve nav sidebar: ${xhr.status} ${xhr.statusText}`);
    }
    return xhr.text();
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 */
  private static replaceOttoUp(ottoUpNode: HTMLElement, rule: string, html: string) {
    /*                                 */
    const range = document.createRange();
    range.selectNodeContents(ottoUpNode);

    /*                                                               */
    const fragment = range.createContextualFragment(html);

    /*           */
    range.deleteContents();
    range.insertNode(fragment);

    /*                     */
    ottoUpNode.dataset.rule = rule;

    /*                                                                     */
    window.preload_polyfill_invoke(ottoUpNode);
  }

  private static uri(ruleString: string) {
    return `/up-teaser/productlist?dreson=${ruleString}&${getMergeParameterQuery()}`;
  }

  private static emitOttoUpLoaded() {
    eventQBus.emit("ftfind.ottoUp.loaded");
  }
}
