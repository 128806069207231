import { DresonRuleResolvedEvent } from "../../types/FilterTypes";
import { eventQBus } from "../../types/EventQBus";
import { getMergeParameterQuery } from "../../tracking/TrackingAPI";

export default class LoadBreadCrumb {
  private static readonly SES: string = "Suchergebnisseite";

  static async onLoadBreadcrumb(event: DresonRuleResolvedEvent) {
    const breadcrumbNode: HTMLElement | null =
      document.getElementById("reptile-breadcrumb") || document.querySelector(".breadcrumb");
    if (!breadcrumbNode) {
      return;
    }

    const { rule } = breadcrumbNode.dataset;
    if (rule === event.rule || !event.rule) {
      return;
    }

    await fetch(LoadBreadCrumb.uri(event))
      .then(LoadBreadCrumb.extractHtmlFragment)
      .then((html: string) => LoadBreadCrumb.replaceBreadcrumb(breadcrumbNode, event.rule, html))
      .then(LoadBreadCrumb.emitBreadcrumbLoaded)
      .catch((e: Error) => {
        console.warn(`Filter reload AJAX GET against ${LoadBreadCrumb.uri(event)} failed: ${e.message}`);
      });
  }

  private static extractHtmlFragment(xhr: Response) {
    if (xhr.status !== 200) {
      throw Error(`Failed to retrieve breadcrumb: ${xhr.status} ${xhr.statusText}`);
    }
    return xhr.text();
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 */
  private static replaceBreadcrumb(breadcrumbNode: HTMLElement, rule: string, html: string) {
    /*            */
    LoadBreadCrumb.freezeHeight(breadcrumbNode);

    /*                                 */
    const range = document.createRange();
    range.selectNodeContents(breadcrumbNode);

    /*                                                               */
    const fragment = range.createContextualFragment(html);

    /*           */
    range.deleteContents();
    range.insertNode(fragment);

    /*                     */
    breadcrumbNode.dataset.rule = rule;

    /*                                                                     */
    window.preload_polyfill_invoke(breadcrumbNode);

    /*              */
    LoadBreadCrumb.unfreezeHeight(breadcrumbNode);
  }

  private static emitBreadcrumbLoaded() {
    eventQBus.emit("ftfind.breadcrumb.loaded");
  }

  private static currentPageType() {
    const elem = document.getElementById("avContent");
    return elem && elem.dataset.pt;
  }

  private static isSearchResultsPage() {
    return LoadBreadCrumb.currentPageType() === LoadBreadCrumb.SES;
  }

  private static uri(data: DresonRuleResolvedEvent): string {
    if (this.isSearchResultsPage()) {
      return this.buildNavSearchUri(data);
    } else {
      return this.buildNavProductListUri(data);
    }
  }

  private static buildNavProductListUri(data: DresonRuleResolvedEvent) {
    return `/nav-grimm/productlist/${data.rule}?${getMergeParameterQuery()}`;
  }

  private static buildNavSearchUri(data: DresonRuleResolvedEvent) {
    /*                                                                                                                                                                         */
    return `/nav-grimm/search/${encodeURIComponent(data.rule)}?encoded=true&${getMergeParameterQuery()}`;
  }

  private static freezeHeight(elem: HTMLElement) {
    const height = elem.getBoundingClientRect().height;
    elem.style.height = `${height}px`;
  }

  private static unfreezeHeight(elem: HTMLElement) {
    window.setTimeout(elem.style.removeProperty.bind(elem.style, "height"), 50);
  }
}
