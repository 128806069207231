import { DresonRuleResolvedEvent } from "../../types/FilterTypes";
import { eventQBus } from "../../types/EventQBus";

export default class LoadNavSidebarText {
  static onLoadNavSidebarText(data: DresonRuleResolvedEvent): void {
    const navSidebarTextNode: HTMLElement | null =
      document.getElementById("reptile-sidebar-text") || document.getElementById("san_navSidebarText");
    if (!navSidebarTextNode) {
      return;
    }

    const { rule } = navSidebarTextNode.dataset;
    if (rule === data.rule) {
      return;
    }

    fetch(LoadNavSidebarText.uri(data.rule))
      .then(LoadNavSidebarText.extractResponseText)
      .then((html: string) => LoadNavSidebarText.replaceNavSidebarText(navSidebarTextNode, data.rule, html))
      .then(LoadNavSidebarText.emitNavSidebarTextLoaded)
      .catch((e: Error) => {
        console.warn(`Filter reload AJAX GET against ${LoadNavSidebarText.uri(data.rule)} failed: ${e.message}`);
      });
  }

  private static extractResponseText(xhr: Response) {
    if (xhr.status !== 200 && xhr.status !== 204) {
      throw Error(`Failed to retrieve nav sidebar: ${xhr.status} ${xhr.statusText}`);
    }
    return xhr.text();
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 */
  private static replaceNavSidebarText(navSidebarTextNode: HTMLElement, rule: string, html: string) {
    /*                                 */
    const range = document.createRange();
    range.selectNodeContents(navSidebarTextNode);

    /*                                                               */
    const fragment = range.createContextualFragment(html);

    /*           */
    range.deleteContents();
    range.insertNode(fragment);

    /*                     */
    navSidebarTextNode.dataset.rule = rule;

    /*                                                                     */
    window.preload_polyfill_invoke(navSidebarTextNode);
  }

  private static uri(ruleString: string) {
    return `/nav-imberator/sidebar/?selektion=${ruleString}`;
  }

  private static emitNavSidebarTextLoaded() {
    eventQBus.emit("ftfind.navSidebarText.loaded");
  }
}
