import { DresonRuleResolvedEvent } from "../../types/FilterTypes";
import { eventQBus } from "../../types/EventQBus";

export default class LoadSeoMetaBlock {
  static onLoadSeoMetaBlock(event: DresonRuleResolvedEvent): void {
    const title: HTMLElement | null = document.querySelector("title");
    const ogTitle: HTMLElement | null = document.querySelector("meta[property='og:title']");
    if (title == null || ogTitle == null) {
      return;
    }

    fetch(LoadSeoMetaBlock.uri(event))
      .then(LoadSeoMetaBlock.extractHtmlFragment)
      .then((html: string) => LoadSeoMetaBlock.replaceMetaBlock(title, ogTitle, html))
      .then(LoadSeoMetaBlock.emitSeoMetaBlockLoaded)
      .catch((e: Error) => {
        console.warn(`Filter reload AJAX GET against ${LoadSeoMetaBlock.uri(event)} failed: ${e.message}`);
      });
  }

  private static extractHtmlFragment(xhr: Response) {
    if (xhr.status !== 200) {
      throw Error(`Failed to retrieve seo meta block: ${xhr.status} ${xhr.statusText}`);
    }
    return xhr.text();
  }

  private static replaceMetaBlock(startNode: HTMLElement, endNode: HTMLElement, html: string) {
    const range = document.createRange();
    range.setStartBefore(startNode);
    range.setEndAfter(endNode);
    const fragment = range.createContextualFragment(html);
    range.deleteContents();
    range.insertNode(fragment);
  }

  private static emitSeoMetaBlockLoaded() {
    eventQBus.emit("reptile.seoMetaBlock.loaded");
  }

  private static uri(data: DresonRuleResolvedEvent): string {
    return `/turtle/meta?rule=${encodeURIComponent(data.rule)}`;
  }
}
