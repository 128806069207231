import { DirectDresonRuleResolveEvent, DresonRuleResolveEvent } from "../../types/FilterTypes";
import { eventQBus } from "../../types/EventQBus";
import { replaceTrackingContext } from "../../tracking/TrackingAPI";
import { breakpoint } from "@otto-ec/global-resources/breakpoint";
import { SfidWrapper } from "../../head/eventHistory/SfidWrapper";

export default class FilterResolveDresonRule {
  private static readonly FILTER_RESOLVER_ENDPOINT = "/grasshopper/filter/resolve/dreson";

  public static async resolve(event: DresonRuleResolveEvent | DirectDresonRuleResolveEvent) {
    /*                                                                */
    const needNotResolve = event.historic || !event.formData;
    if (needNotResolve) {
      if (FilterResolveDresonRule.isInvalidRule(event.rule)) {
        FilterResolveDresonRule.handleError(new Error("Invalid rule for historic or direct event"));
        return;
      }
      if (FilterResolveDresonRule.isSameRule(event.rule)) {
        eventQBus.emit("ftfind.dresonRule.resolved", { ...event, ssid: SfidWrapper.ssid() });
        return;
      }

      /*                                                                                                    */
      const ruleParam = new URLSearchParams({ rule: event.rule }).toString();
      await fetch(`${FilterResolveDresonRule.FILTER_RESOLVER_ENDPOINT}?${ruleParam}`, {
        headers: {
          "X-Find-Trackable": "ts_Assert=none",
        },
      })
        .then(FilterResolveDresonRule.extractResponse)
        .then(FilterResolveDresonRule.replaceSFIDAndAvContent)
        .then(FilterResolveDresonRule.avContent)
        .then(({ redirect }) => {
          replaceTrackingContext("reptile-sfid-wrapper", redirect);
          eventQBus.emit("ftfind.dresonRule.resolved", { ...event, mergeOnPi: true, ssid: SfidWrapper.ssid() });
        });
      return;
    }

    /*                                   */
    if (FilterResolveDresonRule.isInvalidRule(event.formData.get("rule")?.toString() || "")) {
      FilterResolveDresonRule.handleError(new Error("Invalid rule for form event"));
      return;
    }
    await fetch(
      `${FilterResolveDresonRule.FILTER_RESOLVER_ENDPOINT}?${FilterResolveDresonRule.formDataQuery(event.formData)}`,
      {
        headers: {
          "X-Find-Trackable": "ts_Assert=none",
        },
      }
    )
      .then(FilterResolveDresonRule.extractResponse)
      .then(FilterResolveDresonRule.replaceSFIDAndAvContent)
      .then(FilterResolveDresonRule.avContent)
      .then(({ rule, redirect, count }) => {
        replaceTrackingContext("reptile-sfid-wrapper", redirect);
        const parsedCount = count && count.length > 0 ? parseInt(count) : -1;
        const sortOrder = event.formData.get("sortiertnach")?.toString() || "topseller";
        eventQBus.emit("ftfind.dresonRule.resolved", {
          mergeOnPi: true,
          shouldScroll: FilterResolveDresonRule.shouldScroll(),
          page: { sortiertnach: sortOrder },
          rule,
          redirect,
          ssid: SfidWrapper.ssid(),
          ...(parsedCount >= 0 ? { count: parsedCount } : {}),
        });
      })
      .catch(FilterResolveDresonRule.handleError);
  }

  private static isInvalidRule(rule: string): boolean {
    const invalidRules: string[] = ["undefined", "null"];
    if (!rule || invalidRules.some((invalidRule) => invalidRule === rule)) {
      console.warn("Invalid rule");
      return true;
    }
    return false;
  }

  private static isSameRule(rule: string) {
    return rule === FilterResolveDresonRule.currentRule();
  }

  private static formDataQuery(formData: FormData) {
    const convertedFormEntries = Array.from(formData, ([key, value]) => [
      key,
      typeof value === "string" ? value : value.name,
    ]);
    return new URLSearchParams(convertedFormEntries).toString();
  }

  private static extractResponse(xhr: Response) {
    /*                                                               */
    if (xhr.status !== 200 && xhr.status !== 404) {
      throw Error(`Failed to resolve rule: ${xhr.status} ${xhr.statusText}`);
    }
    return xhr.text();
  }

  private static replaceSFIDAndAvContent(response: string) {
    const sfidWrapper: HTMLElement | null = document.getElementById("reptile-sfid-wrapper");
    if (sfidWrapper && window.o_tracking) {
      sfidWrapper.innerHTML = response;
    }
  }

  private static avContent(): { rule: string; redirect: string; count?: string } {
    const element: HTMLElement | null = document.getElementById("avContent");
    const { rule, redirect, count } = element?.dataset || {};
    if (!redirect || !rule) {
      throw Error("Failed to resolve rule: missing redirect or rule");
    }
    return { rule, redirect, count };
  }

  private static currentRule(): string {
    const element: HTMLElement | null = document.getElementById("avContent");
    const { rule } = element?.dataset || {};
    if (!rule) {
      throw Error("Failed to resolve rule: missing rule");
    }
    return rule;
  }

  private static handleError(e: Error): void {
    eventQBus.emit("ftfind.dresonRule.resolveAborted");
  }

  private static shouldScroll() {
    return breakpoint?.isSmall() || breakpoint?.isMedium();
  }
}
