import { DresonRuleResolvedEvent } from "../../types/FilterTypes";
import { eventQBus } from "../../types/EventQBus";

export default class LoadNavConsultantText {
  static onLoadNavConsultantText(data: DresonRuleResolvedEvent): void {
    const navConsultantTextNode: HTMLElement | null =
      document.getElementById("reptile-nav-consultant-text") || document.getElementById("san_navConsultantText");
    if (!navConsultantTextNode) {
      return;
    }

    const { rule } = navConsultantTextNode.dataset;
    if (rule === data.rule) {
      return;
    }

    fetch(LoadNavConsultantText.uri(data.rule))
      .then(LoadNavConsultantText.extractResponseText)
      .then((html: string) => LoadNavConsultantText.replaceNavConsultantText(navConsultantTextNode, data.rule, html))
      .then(LoadNavConsultantText.emitNavConsultantTextLoaded)
      .catch((e: Error) => {
        console.warn(`Filter reload AJAX GET against ${LoadNavConsultantText.uri(data.rule)} failed: ${e.message}`);
      });
  }

  private static extractResponseText(xhr: Response) {
    if (xhr.status !== 200 && xhr.status !== 204) {
      throw Error(`Failed to retrieve nav consultant: ${xhr.status} ${xhr.statusText}`);
    }
    return xhr.text();
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 */
  private static replaceNavConsultantText(navConsultantTextNode: HTMLElement, rule: string, html: string) {
    /*                                 */
    const range = document.createRange();
    range.selectNodeContents(navConsultantTextNode);

    /*                                                               */
    const fragment = range.createContextualFragment(html);

    /*           */
    range.deleteContents();
    range.insertNode(fragment);

    /*                     */
    navConsultantTextNode.dataset.rule = rule;

    /*                                                                     */
    window.preload_polyfill_invoke(navConsultantTextNode);
  }

  private static uri(ruleString: string) {
    return `/nav-imberator/consultant/?selektion=${ruleString}`;
  }

  private static emitNavConsultantTextLoaded() {
    eventQBus.emit("ftfind.navConsultantText.loaded");
  }
}
